<template>
  <div class="section-content-wrapper">
    <component
      :is="getRenderer(currentDocument)"
      :key="currentDocument.id"
      :document="currentDocument"
      @confirm="onSubmit"
      @back="goBack"
    />
  </div>
</template>

<script setup lang="ts">
import type { IDocument, IVisitDocument, IVisitFormDocumentsSection, VisitFormSectionType } from '@einfachgast/shared';
import { useGroupsStore } from '@/store/groups';
import { dynamicFormService } from '@/dynamic-form/dynamic-form-service';
import { useRoute, useRouter } from 'vue-router';
import { computed, defineAsyncComponent, onMounted, ref } from 'vue';
import { useVisitStore } from '@/store/visit';
import { useSectionNavigation } from '@/dynamic-form/composables/use-section-navigation';

const visitStore = useVisitStore();
const groupsStore = useGroupsStore();
const { back, next } = useSectionNavigation();
const route = useRoute();
const currentDocumentIndex = ref(0);

const documents = computed(() => {
  const section: IVisitFormDocumentsSection = dynamicFormService.getCurrentStep(route.params.step as VisitFormSectionType) as IVisitFormDocumentsSection;
  let documents = section?.documents || [];
  if (groupsStore.selectedGroup) {
    documents = documents.filter(document => {
      return document.groups.length === 0 || document.groups?.includes(groupsStore.selectedGroup?.id as string);
    });
  }
  return documents.filter(doc => dynamicFormService.documentMustBeShown(doc));
});

onMounted(() => {
  if (route.query.docId) {
    const targetDocumentIndex = documents.value.findIndex(document => document.id === route.query.docId);
    if (targetDocumentIndex === -1) {
      router.push({ path: route.path, query: {} });
      return;
    }
    currentDocumentIndex.value = targetDocumentIndex;
  }
});

const goBack = () => {
  if (currentDocumentIndex.value === 0) {
    back();
    return;
  }
  currentDocumentIndex.value--;
  router.push({ path: route.path, query: { docId: documents.value[currentDocumentIndex.value].id }});
};

const currentDocument = computed(() => documents.value[currentDocumentIndex.value]);
const router = useRouter();

const onSubmit = (value: IVisitDocument) => {
  // IVisitDocument has no id. But path should be unique as well.
  const existingDocIndex = visitStore.documents.value.findIndex(doc => doc.id === value?.id);
  // if there already is a visit doc, for that document, replace it.
  if (existingDocIndex !== -1) {
    visitStore.documents.value.splice(existingDocIndex, 1, value);
  } else {
    visitStore.documents.value.push(value);
  }

  // last document -> next step
  if (currentDocumentIndex.value === documents.value.length - 1) {
    next();
    return;
  }

  currentDocumentIndex.value++;
  router.push({ path: route.path, query: { docId: documents.value[currentDocumentIndex.value].id }});
};

const rendererConfig = {
  document: defineAsyncComponent(() => import('../../renderers/document/DocumentView.vue')),
  documentText: defineAsyncComponent(() => import('../../renderers/document/DocumentTextView.vue')),
  documentSign: null, // no renderer exist for this type;
};


const getRenderer = (field: IDocument) => {
  if (!field || !field?.type) { // extra check cause of sentry issue (EventId: 76ede858258f4bd2804fadca48f9f100)
    return 'span';
  }
  return rendererConfig[field.type];
};
</script>
