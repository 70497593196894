import type { IVisitFormDocumentsSection, IVisitFormSection, VisitFormSectionType, IDocument } from '@einfachgast/shared';
import { useVisitConditionsStore } from '@/store/visit-conditions';
import { useVisitStore } from '@/store/visit';
import { useGroupsStore } from '../store/groups';

export interface IDynamicFormService {
  readonly entryPoint: VisitFormSectionType | undefined;
}

export class DynamicFormService implements IDynamicFormService {
  private _visitConditionsStore: ReturnType<typeof useVisitConditionsStore> | null = null;
  private _visitStore: ReturnType<typeof useVisitStore> | null = null;
  private _groupsStore: ReturnType<typeof useGroupsStore> | null = null;

  get entryPoint () {
    return this.sections[0].type;
  }

  get sections () {
    return this.removePhotoSectionIfNeeded(this.removeDocumentsSectionIfNeeded(this._visitConditionsStore?.visitForm.sections ?? []));
  }

  removePhotoSectionIfNeeded (sections: Array<IVisitFormSection> = []) {
    const hasPhotoSection = (this._visitConditionsStore?.visitForm?.sections ?? []).some((section) => section.type === 'photo');

    // if there is no photo Section we can simply return sections
    if (!hasPhotoSection) {
      return sections;
    }

    const group = this._groupsStore?.selectedGroup ?? this._groupsStore?.defaultGroup;
    if (!group?.photoEnabled) {
      return (sections ?? []).filter(x => x.type !== 'photo');
    }
    return sections;
  };

  removeDocumentsSectionIfNeeded (sections: Array<IVisitFormSection> = []) {
    const documentsSection = this._visitConditionsStore?.visitForm?.sections?.find((section) => section.type === 'documents') as IVisitFormDocumentsSection;
    // if there is no documents section we can simply return sections
    if (!documentsSection) {
      return sections;
    }

    const filteredDocuments = documentsSection.documents
      .filter((document) => {
        return !document.groups || // no groups are set => document is not restricted to certain groups => keep it
        document.groups.length === 0 || // no groups are set => document is not restricted to certain groups => keep it
        document.groups.includes(this._groupsStore?.selectedGroup?.id ?? '');// document has groups => check if the selected group is one of them. If not, remove it
      })
      .filter(this.documentMustBeShown.bind(this));

    const docCount = filteredDocuments.length ?? 0;

    // If there Are Documents that are restricted to certain groups
    // And the selected group leads to no docs being left over => remove the documents section
    if (docCount <= 0) {
      return sections.filter(x => x.type !== 'documents') || [];
    }

    // If none of our checks apply => simply return sections
    return sections;
  }

  init () {
    this._visitConditionsStore = useVisitConditionsStore();
    this._groupsStore = useGroupsStore();
    this._visitStore = useVisitStore();
  }

  documentMustBeShown  (document: IDocument) {
    if (this._visitStore?.lastCheckInTimestamp) {
      // document?.validity?.value === 0 => document has option "show on updated document"
      if (document?.validity?.value === 0 ) {
        // display doc when lastUpdate is not equal documentsUpdatedCache timestamp >= so when the doc has changed
        return this._visitStore.documentsUpdatedCache[document.id]
          && this._visitStore.documentsUpdatedCache[document.id] !== document.lastUpdatedAtTimestamp; 
      } else if (typeof document.validity === 'object' && document.validity !== null) {
        const validity = document.validity;
        const validityDate = new Date();
        switch (validity.unit) {
          case 'days':
            validityDate.setDate(validityDate.getDate() - validity.value);
            break;
          case 'months':
            validityDate.setMonth(validityDate.getMonth() - validity.value);
            break;
          case 'years':
            validityDate.setFullYear(validityDate.getFullYear() - validity.value);
            break; 
        }
        if (new Date(this._visitStore.lastCheckInTimestamp) < validityDate) {
          return false; // not display doc when last checkin is younger than validity
        }
      }
    }
    return true;
  }

  getNextStep (currentStep: VisitFormSectionType): IVisitFormSection {
    const nextStep = this.sections[(this.getCurrentStepIndex(currentStep) || 0) + 1];
    return nextStep;
  }

  getCurrentStep (currentStep: VisitFormSectionType) {
    return this.sections.find((section) => section.type === currentStep);
  }

  getPreviousStep (currentStep: VisitFormSectionType) {
    const previousStep = this.sections[(this.getCurrentStepIndex(currentStep) || 0) - 1];
    return previousStep;
  }

  getIsFirstStep (currentStep: VisitFormSectionType) {
    return this.findStepIndexByType(currentStep) === 0;
  }

  getIsLastStep (currentStep: VisitFormSectionType) {
    // -2 because success step is handled differently by the success page
    return this.findStepIndexByType(currentStep) === this.sections.length - 2;
  }

  getCurrentStepIndex (currentStep: VisitFormSectionType) {
    const currentStepIndex = this.findStepIndexByType(currentStep);
    if (currentStepIndex === -1) {
      return 0;
    }
    return currentStepIndex;
  }

  private findStepIndexByType (type: VisitFormSectionType) {
    return this.sections.findIndex((section) => section.type === type);
  }
}

const dynamicFormService = new DynamicFormService();

export { dynamicFormService };