const cz = {
  'welcomeScreenHeading': 'Vítejte',
  'requiredCheckboxMessage': 'Prosím, potvrďte zaškrtávací políčko',
  'pleaseSelect': 'Prosím vyberte…',
  'cancel': 'Zrušit',
  'ok': 'OK',
  'yes': 'Ano',
  'back': 'Zpět',
  'welcome': 'Vítejte',
  'no': 'Ne',
  'pleaseEnter': 'Prosím, zadejte…',
  'thanksForYourCheckin': 'Děkujeme za vaši registraci.',
  'checkoutConfirmTitle': 'Potvrdit odhlášení',
  'checkoutConfirmMessage': 'Prosím, odhlaste se, až po ukončení vaší návštěvy.',
  'yesCheckout': 'Odhlásit se nyní',
  'de': 'Deutsch',
  'en': 'English',
  'es': 'Español',
  'fr': 'Français',
  'it': 'Italiano',
  'pt': 'Português',
  'jp': '日本語',
  'nl': 'Nederlands',
  'pl': 'Polski',
  'ru': 'Русский',
  'tr': 'Türkçe',
  'cn': '中国人',
  'hu': 'Magyarország',
  'cz': 'Česká republika',
  'si': 'Slovenija',
  'ua': 'Україна (Ukrayina)',
  'ro': 'România',
  'hr': 'Hrvatska',
  'thanksForYourVisit': 'Děkujeme za vaši návštěvu.',
  'youCanCloseThisPageNow': 'Nyní můžete tuto stránku zavřít.',
  'checkedOutViewTitle': 'Jste odhlášeni',
  'checkoutMessage': 'Odhlasujeme vás…',
  'checkout': 'Odhlásit se',
  'invalidNameFormat': 'Prosím, zadejte celé jméno.',
  'clickToSign': 'klikněte pro podpis',
  'invalidQrCodeHeading': 'Neplatný QR kód',
  'invalidQrCodeText': 'Prosím, obraťte se na recepci.',
  'checkedOut': 'Odhlášeno',
  'checkinDeniedHeading': 'Bohužel vás nemůžeme přihlásit.',
  'next': 'Další',
  'groupSelectionHeading': 'Vyberte skupinu návštěvníků:',
  'checkinMessage': 'Přihlašujeme vás…',
  'aServiceOf': 'Služba od',
  'checkedIn': 'Přihlášeno',
  'CheckedInInfo': 'Prosím, nechte tuto stránku otevřenou, abyste se po ukončení návštěvy mohli odhlásit.',
  'checkin': 'Přihlásit se',
  'delete': 'Smazat',
  'pleaseCheckYourInput': 'Prosím, zkontrolujte své údaje',
  'signAndProceed': 'Podepište se',
  'signatureRequiredMessage': 'Prosím, podepište dokument',
  'requiredField': 'Povinné pole',
  'invalidEmailMessage': 'Prosím, zadejte platnou e-mailovou adresu',
  'pleaseEnterANumber': 'Prosím, zadejte číslo',
  'pleaseEnterAValidNumber': 'Prosím, zadejte platné celé číslo',
  'invalidUrlMessage': 'Prosím, zadejte platnou URL, např. https://www.example.com',
  'functionsError.noSubscriptionFound': 'Momentálně vás nemůžeme přihlásit. Prosím, obraťte se na recepci.',
  'checkinLoading': 'Přihlašování…',
  'functionsError.denyOnValidationFailed': 'Na základě vašich údajů vás nemůžeme přihlásit. Prosím, obraťte se na recepci.',
  'functionsError.missingRequiredInput': 'Protože jste nevyplnili všechna povinná pole, nemůžeme vás přihlásit. Prosím, obraťte se na recepci.',
  'functionsError.invalidInputData': 'Zadali jste neplatné údaje. Prosím, obraťte se na recepci.',
  'functionsError.checkinTokenExpired': 'Platnost vašeho přihlašovacího odkazu vypršela. Prosím, znovu naskenujte QR kód nebo se obraťte na recepci.',
  'startCamera': 'Klepněte pro aktivaci kamery.',
  'cameraLoading': 'Načítání…',
  'photoSectionTitle': 'Pro přihlášení prosím pořiďte svou fotografii.',
  'photoSectionDescription': 'Přístup ke kameře a fotografie budou použity výhradně pro ověření vaší identity pro přihlášení.',
  'takePhoto': 'Pořídit fotografii',
  'reTakePhoto': 'Pořídit znovu',
  'cameraForbidden': 'Přístup ke kameře byl odmítnut.',
  'fullname': 'Celé jméno',
  'Details': 'Detaily',
  'visitorIdCardWillBePrinted': 'Váš návštěvnický průkaz se tiskne ...',
  'visitorIdCardHasBeenPrinted': 'Váš návštěvnický průkaz byl vytištěn!',
};

export { cz };
