const hu = {
  'welcomeScreenHeading': 'Üdvözöljük',
  'requiredCheckboxMessage': 'Kérjük, erősítse meg a jelölőnégyzetet',
  'pleaseSelect': 'Kérjük, válasszon...',
  'cancel': 'Mégse',
  'ok': 'OK',
  'yes':'Igen',
  'back': 'Vissza',
  'welcome': 'Üdvözöljük',
  'no':'Nem',
  'pleaseEnter': 'Kérjük, adja meg...',
  'thanksForYourCheckin': 'Köszönjük a bejelentkezését.',
  'checkoutConfirmTitle': 'Kijelentkezés megerősítése',
  'checkoutConfirmMessage': 'Kérjük, csak akkor jelentkezzen ki, ha befejezte a látogatását.',
  'yesCheckout': 'Most kijelentkezik',
  'de': 'Deutsch',
  'en': 'English',
  'es': 'Español',
  'fr': 'Français',
  'it': 'Italiano',
  'pt': 'Português',
  'jp': '日本語',
  'nl': 'Nederlands',
  'pl': 'Polski',
  'ru': 'Русский',
  'tr': 'Türkçe',
  'cn': '中国人',
  'hu': 'Magyarország',
  'cz': 'Česká republika',
  'si': 'Slovenija',
  'ua': 'Україна (Ukrayina)',
  'ro': 'România',
  'hr': 'Hrvatska',
  'thanksForYourVisit': 'Köszönjük a látogatását.',
  'youCanCloseThisPageNow': 'Most már bezárhatja ezt az oldalt.',
  'checkedOutViewTitle': 'Ön kijelentkezett',
  'checkoutMessage': 'Kijelentkezés...',
  'checkout': 'Kijelentkezés',
  'invalidNameFormat': 'Kérjük, adja meg a teljes nevet.',
  'clickToSign': 'Kattintson az aláíráshoz',
  'invalidQrCodeHeading': 'Érvénytelen QR-kód',
  'invalidQrCodeText': 'Kérjük, forduljon a recepciónhoz.',
  'checkedOut': 'Kijelentkezett',
  'checkinDeniedHeading': 'Sajnos nem tudjuk bejelentkeztetni.',
  'next': 'Tovább',
  'groupSelectionHeading': 'Válasszon egy látogatói csoportot:',
  'checkinMessage': 'Bejelentkezés...',
  'aServiceOf': 'Egy szolgáltatás',
  'checkedIn': 'Bejelentkezett',
  'CheckedInInfo': 'Kérjük, hagyja ezt az oldalt nyitva, hogy befejezése után kijelentkezzen.',
  'checkin': 'Bejelentkezés',
  'delete': 'Törlés',
  'pleaseCheckYourInput': 'Kérjük, ellenőrizze a megadott adatokat',
  'signAndProceed': 'Aláír és folytat',
  'signatureRequiredMessage': 'Kérjük, írja alá a dokumentumot',
  'requiredField': 'Kötelező mező',
  'invalidEmailMessage': 'Kérjük, adjon meg egy érvényes e-mail címet',
  'pleaseEnterANumber': 'Kérjük, adjon meg egy számot',
  'pleaseEnterAValidNumber': 'Kérjük, adjon meg egy érvényes egész számot',
  'invalidUrlMessage': 'Kérjük, adjon meg egy érvényes URL-t, pl. https://www.example.com',
  'functionsError.noSubscriptionFound': 'Jelenleg nem tudjuk bejelentkeztetni. Kérjük, forduljon a recepciónhoz.',
  'checkinLoading': 'Bejelentkezés...',
  'functionsError.denyOnValidationFailed': 'Az adatai alapján nem tudjuk bejelentkeztetni. Kérjük, forduljon a recepciónhoz.',
  'functionsError.missingRequiredInput': 'Mivel nem töltötte ki az összes kötelező mezőt, nem tudjuk bejelentkeztetni. Kérjük, forduljon a recepciónhoz.',
  'functionsError.invalidInputData': 'Érvénytelen adatokat adott meg. Kérjük, forduljon a recepciónhoz.',
  'functionsError.checkinTokenExpired': 'Az Ön bejelentkezési linkje lejárt. Kérjük, scannelje be újra a QR-kódot, vagy forduljon a recepciónhoz.',
  'startCamera': 'Kattintson a kamera aktiválásához.',
  'cameraLoading': 'Betöltés...',
  'photoSectionTitle': 'Kérjük, készítsen egy fényképet a bejelentkezéshez.',
  'photoSectionDescription': 'A kamera hozzáférése és a fénykép kizárólag az Ön azonosításának ellenőrzésére szolgál a bejelentkezés során.',
  'takePhoto': 'Fénykép készítése',
  'reTakePhoto': 'Új fénykép készítése',
  'cameraForbidden': 'A kamera hozzáférés megtagadva.',
  'fullname': 'Teljes név',
  'Details': 'Részletek',
  'visitorIdCardWillBePrinted': 'Az Ön látogatói igazolványa nyomtatás alatt van ...',
  'visitorIdCardHasBeenPrinted': 'Az Ön látogatói igazolványa kinyomtatva!',
};

export { hu };
