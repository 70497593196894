<template>
  <div class="success-wrapper text-center" :class="{ 'with-visitor-id-card-template': hasVisitorIdCard }">
    <v-icon
      :icon="mdiAccountCheck"
      :size="100"
      color="success"
      class="mb-4"
    />
    <h1 class="view-heading text-h5 mb-4">{{ $translate('thanksForYourCheckin') }}</h1>
    <div class="checked-in-text px-12">
      <p
        v-if="successText"
        class="my-4"
      >
        {{ successText }}
      </p>
    </div>
    <div v-if="hasVisitorIdCard">
      <div v-if="visitorIdCardWillBePrinted && !hidePrinterText" class="printer-info-text">
        <span v-if="visitStore.visitorIdCardBase64" class="success">{{ $translate('visitorIdCardHasBeenPrinted') }}</span>
        <span v-else>{{ $translate('visitorIdCardWillBePrinted') }}</span>
      </div>
      <div  class="visitor-id-card-template-wrapper">
        <img v-if="visitStore.visitorIdCardBase64" :src="'data:image/png;base64,' + visitStore.visitorIdCardBase64" />
        <div
          v-else
          class="loading-wrapper"
        >
          <v-skeleton-loader />
        </div>
      </div>
    </div>
    <div class="bottom-content px-4 py-5">
      <p
        class="info-text  px-5"
      >
        {{ $translate('CheckedInInfo') }}
      </p>
      <v-dialog width="500">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            color="red"
            elevation="0"
            size="large"
            min-width="70%"
            class="mt-4"
            :text="$translate('checkout')"
            data-qa="checkout-button"
          />
        </template>

        <template #default="{ isActive }">
          <v-card>
            <v-card-text>
              <p>{{ $translate('checkoutConfirmMessage') }}</p>
            </v-card-text>

            <v-card-actions>
              <v-btn
                variant="flat"
                @click="isActive.value = false"
              >
                {{ $translate('cancel') }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                variant="flat"
                data-qa="checkout-confirm-button"
                @click="checkout"
              >
                {{ $translate('yesCheckout') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVisitConditionsStore } from '@/store/visit-conditions';
import type { IVisitFormTextSection } from '@einfachgast/shared';
import { computed, ref, onMounted } from 'vue';
import { translate } from '@/plugins/translator';
import { mdiAccountCheck } from '@mdi/js';
import { useVisitStore } from '@/store/visit';
import { VisitApi } from '@/api/visit-api';
import { IVisitApi } from '@/interfaces/api/i-visit-api';

const visitApi: IVisitApi = new VisitApi();
const visitStore = useVisitStore();

const visitConditionsStore = useVisitConditionsStore();
const successSection = computed(() => visitConditionsStore.visitSettings?.visitFormSchema.sections.find((section) => section.type === 'successText'));
const successText = computed(() => translate((successSection.value as IVisitFormTextSection)?.content));
const emits = defineEmits(['checkout']);
const checkout = () => {
  emits('checkout');
};
const hidePrinterText = ref(false);

const hasVisitorIdCard = computed(() => !!visitStore.generateVisitorIdCard);
const visitorIdCardWillBePrinted = computed(() => visitStore.visitorIdCardWillBePrinted);

onMounted(async () => {
  if (hasVisitorIdCard.value && !visitStore.visitorIdCardBase64) {
    visitStore.setVisitorIdCardBase64(await visitApi.generateVisitorIdCard(visitStore.pendingVisitToken as string));
    // image is generated => start hide timer;
    setTimeout(() => {
      hidePrinterText.value = true;
    }, 60000);
  }
});
</script>
<style lang="scss" scoped>
.success-wrapper {
  margin-top: 25vh;
}
.printer-info-text {
  font-weight: bold;
  .success {
    color: #1bbc65;
  }
}
.success-wrapper.with-visitor-id-card-template {
  margin-top: 0vh;
}
.bottom-content {
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0;
  background: #fff;
  box-shadow: 0 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, .2)), 0 2px 2px 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, .14)), 0 1px 5px 0 var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, .12));
}
.info-text {
  color: #9e9e9e;
  font-size: .9rem;
}
.visitor-id-card-template-wrapper {
  min-height: 190px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 5px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: center;
}
.visitor-id-card-template-wrapper img {
  width: 100%;
  height: 100%;
  max-width: 300px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #ccc;
}
.loading-wrapper {
  display: flex;
  width: 300px;
  height: 185px;
  border: 1px solid #ccc;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  background: #ffffffb5;
  .v-skeleton-loader {
    width: 100%;
    height: 100%;
  }
}
</style>

