const nl = {
  'welcomeScreenHeading': 'Welkom',
  'requiredCheckboxMessage': 'Gelieve het selectievakje aan te vinken',
  'pleaseSelect': 'Selecteer alstublieft...',
  'cancel': 'Annuleren',
  'ok': 'OK',
  'yes': 'Ja',
  'back': 'Terug',
  'welcome': 'Welkom',
  'no': 'Nee',
  'pleaseEnter': 'Gelieve in te voeren...',
  'thanksForYourCheckin': 'Bedankt voor uw aanmelding.',
  'checkoutConfirmTitle': 'Bevestig afmelden',
  'checkoutConfirmMessage': 'Gelieve pas af te melden wanneer uw bezoek is beëindigd.',
  'yesCheckout': 'Nu afmelden',
  'de': 'Deutsch',
  'en': 'English',
  'es': 'Español',
  'fr': 'Français',
  'it': 'Italiano',
  'pt': 'Português',
  'jp': '日本語',
  'nl': 'Nederlands',
  'pl': 'Polski',
  'ru': 'Русский',
  'tr': 'Türkçe',
  'cn': '中国人',
  'hu': 'Magyarország',
  'cz': 'Česká republika',
  'si': 'Slovenija',
  'ua': 'Україна (Ukrayina)',
  'ro': 'România',
  'hr': 'Hrvatska',
  'thanksForYourVisit': 'Bedankt voor uw bezoek.',
  'youCanCloseThisPageNow': 'U kunt deze pagina nu sluiten.',
  'checkedOutViewTitle': 'U bent afgemeld',
  'checkoutMessage': 'We melden u af...',
  'checkout': 'Afmelden',
  'invalidNameFormat': 'Gelieve een volledige naam op te geven.',
  'clickToSign': 'Klik om te ondertekenen',
  'invalidQrCodeHeading': 'Ongeldige QR-code',
  'invalidQrCodeText': 'Gelieve contact op te nemen met het ontvangstpersoneel.',
  'checkedOut': 'Afgemeld',
  'checkinDeniedHeading': 'We kunnen u helaas niet aanmelden.',
  'next': 'Volgende',
  'groupSelectionHeading': 'Selecteer een bezoekersgroep:',
  'checkinMessage': 'We melden u aan...',
  'aServiceOf': 'Een dienst van',
  'checkedIn': 'Aangemeld',
  'CheckedInInfo': 'Laat deze pagina open staan om u af te melden nadat uw bezoek is voltooid.',
  'checkin': 'Aanmelden',
  'delete': 'Verwijderen',
  'pleaseCheckYourInput': 'Gelieve uw invoer te controleren',
  'signAndProceed': 'Ondertekenen',
  'signatureRequiredMessage': 'Gelieve het document te ondertekenen',
  'requiredField': 'Verplicht veld',
  'invalidEmailMessage': 'Gelieve een geldig e-mailadres in te voeren',
  'pleaseEnterANumber': 'Gelieve een nummer in te voeren',
  'pleaseEnterAValidNumber': 'Gelieve een geldig geheel getal in te voeren',
  'invalidUrlMessage': 'Gelieve een geldige URL in te voeren, bijv. https://www.example.com',
  'functionsError.noSubscriptionFound': 'We kunnen u momenteel niet aanmelden. Gelieve contact op te nemen met het ontvangstpersoneel.',
  'checkinLoading': 'Aanmelden...',
  'functionsError.denyOnValidationFailed': 'We kunnen u niet aanmelden vanwege uw gegevens. Gelieve contact op te nemen met het ontvangstpersoneel.',
  'functionsError.missingRequiredInput': 'Omdat u niet alle verplichte velden heeft ingevuld, kunnen we u niet aanmelden. Gelieve contact op te nemen met het ontvangstpersoneel.',
  'functionsError.invalidInputData': 'U heeft ongeldige gegevens ingevoerd. Gelieve contact op te nemen met het ontvangstpersoneel.',
  'functionsError.checkinTokenExpired': 'Uw aanmeldlink is verlopen. Scan alstublieft de QR-code opnieuw in of neem contact op met het ontvangstpersoneel.',
  'startCamera': 'Tik om de camera te activeren.',
  'cameraLoading': 'Laden...',
  'photoSectionTitle': 'Maak alstublieft een foto van uzelf voor de aanmelding.',
  'photoSectionDescription': 'De toegang tot de camera en de foto worden uitsluitend gebruikt om uw identiteit te controleren voor de aanmelding.',
  'takePhoto': 'Foto nemen',
  'reTakePhoto': 'Opnieuw nemen',
  'cameraForbidden': 'Toegang tot de camera geweigerd.',
  'fullname': 'Volledige naam',
  'Details': 'Details',
  'visitorIdCardWillBePrinted': 'Uw bezoekersbadge wordt afgedrukt ...',
  'visitorIdCardHasBeenPrinted': 'Uw bezoekersbadge is gedrukt!',
};

export { nl };
